import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const SchoolRoutes = lazy(() => import("../src/routes/SchoolRoutes"));
const ErpRoutes = lazy(() => import("../src/routes/ErpRoutes"));

function App() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/*" element={<SchoolRoutes />} />
          <Route path="/school-erp/*" element={<ErpRoutes />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
